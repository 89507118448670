<template>
  <div>
    <!-- P C -->
    <div class="detailsBox noSel flex j_s hidden-xs-only">
      <Loading v-show="isLoading"></Loading>
      <div class="swiper">
        <el-carousel height="460px" indicator-position="outside">
          <el-carousel-item v-for="(item,i) in banner" :key="i">
            <img :src="item" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="info">
        <div class="infoText">
          <div class="textName">{{shopInfo.title}}</div>
          <div class="textNameE"></div>
          <div style="margin: 15px 0">
            <!-- <span class="textHs">60ml</span>
            <span class="textPrice">￥{{shopInfo.price}}</span>-->
            <div class="skuBox flex a_c">
              <div
                class="skuBoxList"
                :class="{on:skuChoseIndex == i}"
                v-for="(item,i) in shopInfo.sku"
                :key="i"
                @click="skuChoseIndex = i"
              >
                <div class="title">{{item.title}}</div>
                <div class="price">￥{{item.price}}</div>
              </div>
            </div>
            <div style="font-size: 24px;">￥{{shopInfo.sku[skuChoseIndex].price}}</div>
          </div>
          <div class="operation flex a_c">
            <div class="countNum_box flex j_s a_c">
              <div @click="reduceCount()" class="operBox reduce">
                <img src="https://static.5t1800.com/s2/2021/06/17/60caeaf7cdaa0.png" alt />
              </div>
              <div class="num">{{ count }}</div>
              <div @click="addCount()" class="operBox add">
                <img src="https://static.5t1800.com/s2/2021/06/17/60caeaf4e670f.png" alt />
              </div>
            </div>
            <div @click="shopCartAdd" class="operation_box">加入购物车</div>
            <div class="operation_box flex j_c a_c">
              <span @click="toBuy">立即购买</span>
              <img src="https://static.5t1800.com/s2/2021/06/16/60c9970ec30a3.png" alt />
            </div>
          </div>
        </div>
        <div class="infoType">
          <div v-for="(item, i) in infoTypeList" :key="i">
            <div @click="openText(i)" class="flex j_s a_c" style="height: 36px;cursor:pointer">
              <span>{{ item.name }}</span>
              <div class="checkIcon">
                <img
                  v-show="!item.isOpen"
                  class="plus"
                  src="https://static.5t1800.com/s2/2021/06/16/60c99a837f3f4.png"
                  alt
                />
                <img
                  v-show="item.isOpen"
                  class="reduce"
                  src="https://static.5t1800.com/s2/2021/06/16/60c9a1be96063.png"
                  alt
                />
              </div>
            </div>
            <div v-show="item.isOpen">
              <div v-if="testStr(item.value)" class="infoType_text">{{ item.value }}</div>
              <ul v-if="testObj(item.body)" class="infoType_text">
                <li v-for="(items, i2) in item.body" :key="i2">{{ items }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contantImg hidden-xs-only">
      <div v-for="(item,i) in shopInfo.content" :key="i">
        <div v-html="item"></div>
      </div>
    </div>

    <!-- H5 -->
    <div class="detailsBoxH5 noSel hidden-sm-and-up">
      <Loading v-show="isLoading"></Loading>
      <div class="swiper">
        <el-carousel height="375px" indicator-position="outside">
          <el-carousel-item v-for="(item,i) in banner" :key="i">
            <img :src="item" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="infoH5">
        <div class="infoText">
          <div class="textName">{{shopInfo.title}}</div>
          <div class="textNameE"></div>
          <div style="margin: 15px 0">
            <div class="skuBox flex a_c">
              <div
                class="skuBoxListH5"
                :class="{on:skuChoseIndex == i}"
                v-for="(item,i) in shopInfo.sku"
                :key="i"
                @click="skuChoseIndex = i"
              >
                <div class="title">{{item.title}}</div>
                <div class="price">￥{{item.price}}</div>
              </div>
            </div>
            <div style="font-size: 24px;">￥{{shopInfo.sku[skuChoseIndex].price}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="H5WX hidden-sm-and-up">
      <img src="https://static.5t1800.com/s2/2021/07/28/610127b7ae9b7.png" alt />
    </div>
    <div class="contantImgH5 hidden-sm-and-up">
      <div v-for="(item,i) in shopInfo.content" :key="i">
        <div v-html="item" class="H5ContantImg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../loading";
import api from "@/api/api";
import storage from "@/utils/storage";
export default {
  name: "",
  props: {},
  data() {
    return {
      banner: [
        "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
        "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png"
      ],
      infoTypeList: [
        {
          name: "描述",
          body:
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
          isOpen: true
        },
        {
          name: "产品功效",
          body:
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
          isOpen: false
        },
        {
          name: "适合肌肤类型",
          body: [
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜"
          ],
          isOpen: false
        },
        {
          name: "使用方法",
          body:
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
          isOpen: false
        },
        {
          name: "成分",
          body:
            "保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜保湿面膜立减80元保湿面膜保湿面膜",
          isOpen: false
        }
      ],
      shopInfo: {},
      skuChoseIndex: 0,
      count: 1,
      isLoading: true,
      timer: true
    };
  },
  created() {
    this.getDetails();
    this.searchFormWidth();
  },
  methods: {
    //加入购物车
    shopCartAdd() {
      if (this.timer) {
        this.timer = false;
        var shop = {},
          goods_params = [],
          i = this.skuChoseIndex;
        goods_params.push({
          id: this.shopInfo.id,
          number: this.count,
          skuid: this.shopInfo.sku[i].id
        });
        shop.img_path = this.shopInfo.sku[i].img_path;
        shop.skuTitle = this.shopInfo.sku[i].title;
        shop.title = this.shopInfo.title;
        shop.price = this.shopInfo.sku[i].price;
        shop.number = this.count;
        shop.skuTitle = this.shopInfo.sku[i].title;
        shop.skuid = this.shopInfo.sku[i].id;
        shop.goods_params = goods_params;
        console.log(shop);
        this.WYfun.getShopCartSto(shop);
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 1500,
          onClose: () => {
            window.location.reload();
          }
        });
        setTimeout(() => {
          this.timer = true;
        }, 1000);
      }
    },
    //立即购买
    toBuy() {
      var settlementInfo = {};
      var goods_params = [],
        goods_info = [];
      var i = this.skuChoseIndex;
      goods_params.push({
        id: this.shopInfo.id,
        number: this.count,
        skuid: this.shopInfo.sku[i].id
      });
      goods_info.push({
        title: this.shopInfo.title,
        skuTitle: this.shopInfo.sku[i].title,
        pic: this.shopInfo.sku[i].img_path,
        price: this.shopInfo.sku[i].price,
        number: this.count,
        skuid: this.shopInfo.sku[i].id
      });
      settlementInfo.goods_params = goods_params;
      settlementInfo.goods_info = goods_info;
      settlementInfo.buy_type = 1;
      storage.set("settlementInfo", settlementInfo, true);
      this.routerTo("settlement");
    },
    //弹出文字内容
    openText(i) {
      console.log(i);
      if (this.infoTypeList[i].isOpen == true) {
        this.infoTypeList[i].isOpen = false;
      } else {
        this.infoTypeList.forEach(item => {
          item.isOpen = false;
        });
        this.infoTypeList[i].isOpen = true;
      }
      this.$forceUpdate();
    },
    //验证数据类型是否为字符串
    testStr(value) {
      var type = typeof value;
      if (type == "string") {
        return true;
      } else {
        return false;
      }
    },
    //验证数据类型是否为对象
    testObj(value) {
      var type = typeof value;
      if (type == "object") {
        return true;
      } else {
        return false;
      }
    },
    //获取商品详情
    getDetails() {
      this.isLoading = true;
      var id = "";
      var productDetails = storage.get("productDetails", true);
      if (productDetails) {
        id = productDetails.id;
      }
      var codes = this.GetQueryValue("codes");
      var params = {
        id: id,
        codes: codes
      };
      api
        .$getGoodsDetails(params)
        .then(res => {
          console.log(res.data);
          this.shopInfo = res.data.data;
          this.banner = res.data.data.banner;
          this.infoTypeList = res.data.data.params;
          if (this.infoTypeList.length != 0) {
            this.infoTypeList.forEach(item => {
              item.isOpen = false;
            });
            this.infoTypeList[0].isOpen = true;
          }
          this.isLoading = false;
          //h5状态下将图片大小转化为撑起;
          if (window.innerWidth < 768) {
            var content = res.data.data.content;
            for (var i = 0; i < content.length; i++) {
              var newImg = content[i];
              newImg = newImg.replace("750px", "100vw");
              content[i] = newImg;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //减少单个商品数量
    reduceCount() {
      if (--this.count < 1) {
        this.count = 1;
      }
    },
    //增加单个商品数量
    addCount() {
      this.count++;
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
    //获取当前浏览器当前窗口大小
    searchFormWidth() {
      let w = window.innerWidth;
      console.log("窗口大小为：", w);
      this.innerWidth = w;
    },
    //获取url参数
    GetQueryValue(queryName) {
      var url = window.location.href;
      url = url.split("?");
      url = url[1];
      if (url) {
        var vars = url.split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == queryName) {
            return pair[1];
          }
        }
      }
      return null;
    }
  },
  components: {
    Loading
  }
};
</script>

<style scoped>
.detailsBox {
  width: 1200px;
  margin: 0 auto;
}
.detailsBox .swiper {
  width: 460px;
}
.detailsBox .swiper img {
  width: 460px;
  height: 460px;
}
.detailsBox .info {
  width: 680px;
}

/* H5 */
.detailsBoxH5 {
  width: 100vw;
  overflow: hidden;
}
.detailsBoxH5 .swiper {
  width: 100vw;
}
.detailsBoxH5 .swiper img {
  width: 100vw;
}
.detailsBoxH5 .infoH5 {
  box-sizing: border-box;
  padding: 0 10px;
  width: 100vw;
}
.infoH5 .infoText .textName {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.infoH5 .infoText .textNameE {
  min-height: 16px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.infoH5 .infoText .textHs {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.infoH5 .infoText .textPrice {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 40px;
}
.skuBoxListH5 {
  width: 80px;
  height: 43px;
  border: 1px solid #999999;
  margin: 0 10px 10px 0;
  text-align: center;
  cursor: pointer;
}

.skuBoxListH5 .title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 8px 0;
}

.skuBoxListH5 .price {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.contantImgH5 {
  width: 100vw;
  overflow: hidden;
}

.info .infoText .textName {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.info .infoText .textNameE {
  min-height: 20px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.info .infoText .textHs {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.info .infoText .textPrice {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 40px;
}
.operation > img {
  width: 33px;
  height: 32px;
  cursor: pointer;
  margin-left: 10px;
}
.operation .operation_box {
  height: 42px;
  padding: 0 16px;
  background: #000000;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  margin-right: 10px;
  cursor: pointer;
}

.operation .operation_box img {
  width: 10px;
  height: 17px;
  margin-left: 4px;
}

.infoType {
  margin-top: 40px;
  border-top: 1px solid #dcdcdc;
}

.infoType > div {
  border-bottom: 1px solid #dcdcdc;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  padding: 9px 0;
}

.infoType > div .plus {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.infoType > div .reduce {
  width: 14px;
  cursor: pointer;
}
.infoType > div .checkIcon {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.infoType .infoType_text {
  line-height: 25px;
}
.infoType ul {
  padding-left: 20px;
}
.infoType ul li {
  list-style: disc !important;
}
/* 轮播图样式修改 */
/deep/.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.skuBox {
  width: 680px;
  flex-wrap: wrap;
}

.skuBox .on {
  border: 1px solid #333333;
}

.skuBoxList {
  width: 120px;
  height: 73px;
  border: 1px solid #999999;
  margin: 0 30px 10px 0;
  text-align: center;
  cursor: pointer;
}

.skuBoxList .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 8px 0;
}

.skuBoxList .price {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.countNum_box {
  height: 40px;
  border: 1px solid #999999;
  margin-right: 10px;
}
.operBox {
  height: 40px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.reduce {
  border-right: 1px solid #999999;
}

.reduce img {
  width: 14px;
}

.add {
  border-left: 1px solid #999999;
}

.add img {
  width: 14px;
  height: 14px;
}
.countNum_box .num {
  padding: 0 7px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 35px;
  text-align: center;
}
.contantImg {
  width: 750px;
  margin: 0 auto;
}

/* h5引导扫码跳转小程序 */
.H5WX {
  border-top:1px solid rgb(231, 231, 231)
}
.H5WX img {
  width: 100vw;
}
</style>
