<template>
  <div>
    <div class="recommendBox" v-if="recommendList.length != 0">
      <div class="header">推荐搭配</div>
      <ul class="list flex a_c j_s">
        <li v-for="(item, i) in recommendList" :key="i">
          <div>
            <img width="100%" height="386px" :src="item.pic" alt="" />
          </div>
          <div class="shopBox">
            <div class="p1">{{ item.name }}</div>
            <div class="p2">{{ item.jieshao }}</div>
          </div>
          <div class="shopBox_bot flex j_s a_c">
            <div class="price">￥{{ item.price | keepTwoNum }}</div>
            <div class="buy">立即购买</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "",
  props: {},
  data() {
    return {
      recommendList: [],
    };
  },
  components: {},
  created() {
    // this.getRecommendList();
  },
  methods: {
    //获取商品详情相关推荐
    getRecommendList() {
      api
        .$getHomeList()
        .then((res) => {
          console.log(res.data);
          this.recommendList = res.data.homeList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.recommendBox {
  width: 1200px;
  margin: 0 auto;
}

.recommendBox .header {
  height: 64px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 64px;
}

/* 商品详情 */
.list {
  width: 1200px;
}
.list li {
  width: 386px;
}
.shopBox {
  padding: 0 11px;
  height: 122px;
}

.shopBox .p1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 20px 0 15px 0;
}

.shopBox .p2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.shopBox_bot {
  height: 47px;
  padding: 10px;
  box-sizing: border-box;
}
.shopBox_bot .buy {
  width: 78px;
  height: 27px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 27px;
}
.shopBox_bot .price {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2742;
}
</style>
