<template>
  <div class="box">
    <Details></Details>
    <Recommend></Recommend>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import Details from "@/components/productDetails/details";
import Recommend from "@/components/productDetails/recommend";
export default {
  data() {
    return {};
  },
  created() {
    this.fixType();
    this.getProductInfo();
  },
  computed: {},
  components: {
    Details,
    Recommend,
  },
  methods: {
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 2);
    },
    //获取商品详情数据
    getProductInfo() {
      this.showProductInfo = storage.get("productDetails", true);
      console.log(this.showProductInfo);
    },
  },
};
</script>

<style scoped>
.box {
  padding: 60px 0;
}
.bigImg img {
  display: block;
  width: 1200px;
  height: 600px;
  margin: 80px auto 40px auto;
}
.bigText {
  max-width: 1200px;
  height: 200px;
  background: #000000;
  margin: 0 auto;
  font-size: 72px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
  line-height: 200px;
  text-align: center;
}
</style>

